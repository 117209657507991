import './foundation/_foundation';
import { nanoid } from 'nanoid';
import device from 'current-device';

// const is_mobile = () => device.mobile();
// const is_tablet = () => device.tablet();

import Cookies from 'js-cookie';
window.Cookies = Cookies;
Object.assign(window, { Cookies });


import { Fancybox } from "@fancyapps/ui";
Fancybox.bind(".wp-block-gallery .wp-block-image a, [id^=\"gallery-\"] a", {
    groupAll: true, // Group all items
});

Fancybox.bind(".fcy-popup, .fcy-video, .banner-video a", {});

//------------------------------------

jQuery(($) => {
    //...
    const onload_events = () => {};

    onload_events();
    $(window).on('load', () => {
        onload_events();
    });

    device.onChangeOrientation(() => {
        onload_events();
    });

    const filter_tabs = $('.w-filter-tabs');
    $.each(filter_tabs, function (i, el) {
        const $el = $(el),
            _id = rand_element_init(el),
            $nav = $el.find('.tabs-nav'),
            $content = $el.find('.tabs-content');

        const _cookie = `cookie_${_id}_${i}`;
        let cookieValue = Cookies.get(_cookie);

        if (!cookieValue) {
            cookieValue = $nav.find('a:first').attr('href');
            Cookies.set(_cookie, cookieValue, { expires: 7, path: '' });
        }
        $nav.find(`a[href="${cookieValue}"]`).addClass('current');
        $nav.find('a')
            .on('click', function (e) {
                e.preventDefault();

                const $this = $(this);
                const hash = $this.attr('href');
                Cookies.set(_cookie, hash, { expires: 7, path: '' });

                $nav.find('a.current').removeClass('current');
                $content.find('.tabs-panel:visible').removeClass('show').hide();

                $($this.attr('href')).addClass('show').show();
                $this.addClass('current');
            })
            .filter('.current')
            .trigger('click');
    });


});

//------------------------------------

/** DOMContentLoaded */
document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('a._blank, a.blank, a[target="_blank"]').forEach((el) => {
        if (!el.hasAttribute('target') || el.getAttribute('target') !== '_blank') {
            el.setAttribute('target', '_blank');
        }

        const relValue = el.getAttribute('rel');
        if (!relValue || !relValue.includes('noopener') || !relValue.includes('nofollow')) {
            const newRelValue = (relValue ? relValue + ' ' : '') + 'noopener nofollow';
            el.setAttribute('rel', newRelValue);
        }
    });

    // javascript disable right click
    //document.addEventListener('contextmenu', event => event.preventDefault());
    // document.addEventListener('contextmenu', function (event) {
    //     if (event.target.nodeName === 'IMG') {
    //         event.preventDefault();
    //     }
    // });

    /** remove style img tag */
    document.querySelectorAll('img').forEach((el) => el.removeAttribute('style'));
});

//------------------------------------

/**
 * @param el
 * @returns {*|jQuery}
 */
function rand_element_init(el) {
    const $el = $(el);
    const _rand = nanoid(9);
    $el.addClass(_rand);

    let _id = $el.attr('id');
    if (!_id) {
        _id = _rand;
        $el.attr('id', _id);
    }

    return _id;
}

jQuery( function( $ ) {

    $(document).ready(function() {

     var   prev_btn = '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="69" viewBox="0 0 32 69" fill="none"><path d="M13.6255 0.833496H32L18.9728 34.7317L32 68.6299H13.6255L0.598263 34.7317L13.6255 0.833496Z" fill="white"/></svg>';
	 var next_btn = '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="69" viewBox="0 0 32 69" fill="none"><g opacity="0.5"><path d="M18.3745 0.833496H0L13.0272 34.7317L0 68.6299H18.3745L31.4017 34.7317L18.3745 0.833496Z" fill="white"/></g></svg>';
    
        //  slie case study
     $('.case_study_home .slide-img-gallery').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
          //  asNavFor:'.case_study_home .slide-thumb-gallery',
            infinite: true,
            speed: 300,
           
            swipeToSlide: true,
            autoplay: false,
            fade:false,
            dots:false,
            autoplaySpeed: 2000,
  
           
            focusOnSelect: true,
            
            prevArrow : '<button type="button" class="slick-prev">'+prev_btn+'</button>',
            nextArrow: '<button type="button" class="slick-next">'+next_btn+'</button>',
            
          });

         var set_thumb = '';
         if ( $('.case_study_home .slide-thumb-gallery img').length >= 6) {
             set_thumb = '.case_study_home .slide-thumb-gallery' ;
         } 
         $('.case_study_home .slide-img-gallery').slick('slickSetOption','asNavFor', set_thumb,true);
         
        $('.case_study_home .slide-thumb-gallery').slick({
            slidesToShow: 5,
            slidesToScroll: 1,
            asNavFor: '.case_study_home .slide-img-gallery',
            dots: true,
            centerMode: false,
            infinite: true,
            cssEase: 'ease-in-out',
            focusOnSelect: true,
            arrows: false,
            responsive: [
                {
                    breakpoint: 1299,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        centerMode: false,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        centerMode: false,
                    }
                }
                ]
          });

        //    slide posts home
          var $slider = $('.list_posts_slide_home');
          $slider.on('init', function(event, slick){
              $('#totalSlides').text('0' + slick.slideCount);
          });
          $slider.on('afterChange', function(event, slick, currentSlide){
              var slideNumber = (currentSlide ? currentSlide : 0) + 1;
              $('#currentSlide').text('0' + slideNumber);
          });
          $slider.slick({
              prevArrow: $('.custom-prev'),
              nextArrow: $('.custom-next'),
              slidesToShow: 3,
              slidesToScroll: 1,
              centerMode: true,
              arrows: true,
              dots: false,
              speed: 300,
              centerPadding: '0px',
              infinite: true,
              autoplaySpeed: 5000,
              autoplay: false,
              swipeToSlide: true,
              responsive: [
                {
                    breakpoint: 1299,
                    settings: { slidesToShow: 3 }
                },
                {
                    breakpoint: 992,
                    settings: {slidesToShow: 3,}
                },
                {
                    breakpoint: 480,
                    settings: { slidesToShow: 1,     
                                centerPadding: '10%',
                                dots: true}
                }
                ]
          });

          $('.custom-prev.second').on('click', function() {
              $slider.slick('slickPrev');
          });

          $('.custom-next.second').on('click', function() {
              $slider.slick('slickNext');
          });

      });

});